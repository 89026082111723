html.dark {
  background-color: theme('colors.background-color');
}

.custom-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-header .logo {
  max-width: 170px;
}
.custom-header .buttons {
  display: flex;
}